import React, { useState, useEffect } from 'react';
import {
  Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Legend, } from "chart.js";
import { Line } from "react-chartjs-2";
import 'bootstrap/dist/css/bootstrap.css';
import Nav from '../../composants/nav.js';
import '../dasboard.css';
import { Autocomplete ,CircularProgress,MenuItem,Box,Dialog,DialogActions, DialogContent, DialogContentText, DialogTitle, TextField,Stack, Container , IconButton, Button, Paper, TableRow, TableHead, TableContainer, TableCell, TableBody, Table, Tooltip} from '@mui/material';
import { Navigate, useNavigate, useParams} from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add.js';
import PrintIcon from '@mui/icons-material/Print';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TableChartIcon from '@mui/icons-material/TableChart';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DescriptionIcon from '@mui/icons-material/Description';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import axios from 'axios';
import { format } from 'date-fns';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import m2agroup from '../images/m2a.png';
import B from '../images/B.png';
import B2 from '../images/B2.png';
import H from '../images/H.png';
import hand from '../images/hand.png';
import laptop from '../images/laptop.png';
import lock from '../images/lock.png';
import T from '../images/T.png';
import techni0 from '../images/techni.png';
import wallet from '../images/wallet.png';
import Lottie from 'lottie-react';
import techni from '../images/lottie/techni.json'
import Installe from '../images/gif/Installe.gif'
import SousGarantie from '../images/gif/SousGarantie.gif'
import EnService from '../images/gif/enService.gif'

const loadingImages = [
     B,
     T,
     techni,
     laptop,
     lock,
     hand,
     B2,     
     wallet
    // Ajoutez autant d'images que nécessaire
  ];

  var BACKEND_URL = 'https://polygonBac.bt-support.net/api/';

  // Enregistrer les composants Chart.js
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Legend);


export default function ReportingProduit() {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [listCommentaire, setListCommentaire] = useState([]);
    const [produit, setproduit] = useState();
    const { compte_id } = useParams();
    const date1=  new Date();
    const dateActuelle = date1.toISOString().split('T')[0];
    const [totalEnService, setTotalEnService] = useState([]);
    const [totalSG, setTotalSG] = useState([]);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [backendErrorMessage, setBackendErrorMessage] = useState('');

    let [currentPage, setCurrentPage] =useState(1);    
    const dataPerPage = 5;
    const lastIndex = currentPage * dataPerPage;
    const firstIndex = lastIndex - dataPerPage;
    const data = listCommentaire.slice(firstIndex, lastIndex);
    const nPage = Math.ceil(listCommentaire.length /dataPerPage);
    const numbers = [...Array(nPage+1).keys()].slice(1);
    const maxPagesToShow = 5;

    const getPageNumbersToShow = () => {
      const startPage = Math.max(1, currentPage - maxPagesToShow);
      const endPage = Math.min(nPage, currentPage + maxPagesToShow);
    
      return [...Array(endPage - startPage + 1).keys()].map((i) => startPage + i);
    };


    const { produit_id } = useParams();

    const [chartData, setChartData] = useState(null);

    
      
    const request = async () => {
      console.log(produit_id)
      try
      {
        await axios.get(`${BACKEND_URL}equipement/equipementForProduit/${produit_id}/${compte_id}`)
        .then(({data}) => {
            setListCommentaire(data);
            console.log(listCommentaire);
        }).catch ((error) =>
        {
            setBackendErrorMessage(error.response.data.error);
        });

        await axios.get(`${BACKEND_URL}produit/show/${produit_id}/${compte_id}`)
        .then(({data}) => {
            setproduit(data);
            console.log(produit);
        }).catch ((error) =>
        {
            setBackendErrorMessage(error.response.data.error);
        });

        await axios.get(`${BACKEND_URL}equipement/getMonthlyEquipmentData/${produit_id}`)
        .then(({data}) => {
            console.log(data)
            // Préparer les labels (mois) et les valeurs (nombre d'équipements)
        const labels = Object.keys(data); // Les mois (YYYY-MM)
        const values = Object.values(data); // Les quantités

        setChartData({
          labels: labels,
          datasets: [
            {
              label: "Équipements créés par mois",
              data: values,
              borderColor: "rgb(53, 162, 235)",
              backgroundColor: "rgba(53, 162, 235, 0.5)",
            },
          ],
        });

        }).catch ((error) =>
        {
            setBackendErrorMessage(error.response.data.error);
        });

        await axios.get(`${BACKEND_URL}equipement/getEquipementStatistics/${produit_id}`)
        .then(({data}) => {
            setTotalEnService(data['totalEnService']);
            setTotalSG(data['totalSousGarantie']);
            console.log(data['totalSousGarantie']);
        }).catch ((error) =>
        {
            setBackendErrorMessage(error.response.data.error);
        });
      } catch (error) {
        console.log(error)
      }
    }

    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: true,
          text: `Equipement`,
        },
      },
    };
   
    const handleLinkClick = (link) => {
        // Ajoutez la logique pour afficher la page correspondante en fonction du lien cliqué
        // Vous pouvez utiliser une bibliothèque de routage ou gérer manuellement le contenu ici
        navigate(`/${link}`)
    };
    
        const handlePrint = () => {
          const printWindow = window.open('', '_blank');
        
        const tableRows = listCommentaire.map((row, index) => (
          `<tr>
            <td>${index + 1}</td>
            <td>${row.nomAgence}</td>
            <td>${row.numeroSerie}</td>
            <td>${dateActuelle>=row.dateDG && dateActuelle<=row.dateFG ?'OUI':'NON'}</td>
            <td>${format(new Date(row.created_at), 'dd-MM-yyyy')}</td>
             <td>${row.statutEquipement}</td>
          </tr>`
        )).join('');
        
        // Utilisez la chaîne HTML générée pour construire le document HTML
        const htmlContent = `
          <html lang="fr">
            <head>
              <meta charset="UTF-8">
              <meta name="viewport" content="width=device-width, initial-scale=1.0">
              <title>Tableau des installations ${produit.nomProd}</title>
              <style>
                body { font-family: Arial, sans-serif; }
                table { width: 100%; border-collapse: collapse; margin-bottom: 20px; }
                th, td { border: 1px solid #ddd; padding: 8px; text-align: left; }
                th { background-color: #f2f2f2; }
                .header {
                  border-bottom: 2px solid #000;
                  margin-bottom: 20px;
                  padding-bottom: 10px;
              }
              .header img {
                  width: 200px;
                  height: auto;
                  float: left;
                  margin-right: 20px;
              }
              .header h1,
              .header p {
                  margin: 0;
                  padding: 0;
              }
              /* Aligne les éléments pour l'impression */
              .header h1 {
                  font-size: 24px;
                  line-height: 1.2;
                  color: red
              }
              .header p {
                  font-size: 14px;
                  line-height: 1.4;
              }
              </style>
            </head>
            <body>
            <div class="printable header">
                    <img src=${m2agroup} alt="Logo">                    
                    <h1>POLYGON BIO SERVICES SARL</h1>
                    <p>09 BP 969 Ouagadougou 09</p>
                    <p>Bureau     : (+226) 25 46 29 21</p>
                    <p>Portable 1 : (+226) 70 80 82 08</p>
                    <p>Portable 2 : (+226) 79 67 11 11</p>
                    <p>Whatsapp/ WeChat : (+226) 76 59 71 28</p>
                    <p>E-mail : polygonbioservice@yahoo.fr</p>
                    <p>www.polygonbioservices.com</p>
                    <p>Situé au quartier Sinyiri côté Ouest de la ZAD (SIAO)</p>
                    <p>Siège social 01 BP 6727 OUAGA 01</p>                    
                </div>
              <h2>Tableau des installations.</h2>
              <table>
                <thead>
                  <tr>
                    <th>Numéro</th>
                    <th>Agence</th>
                    <th>No-Serie</th>
                    <th>Garantie</th>
                    <th>Date installation</th>
                    <th>Statut</th>
                  </tr>
                </thead>
                <tbody>
                  ${tableRows}
                </tbody>
              </table>
            </body>
          </html>`;
        
        // Utilisez document.write pour écrire le contenu HTML dans la fenêtre d'impression
        printWindow.document.write(htmlContent);
        printWindow.document.close();
        printWindow.print();
        };

        useEffect(() => {
          try {
            request();
            
          } catch (error) {
            console.log(error)
          }
            const timer = setTimeout(() => {
              // Mettez à jour l'état pour arrêter le chargement après 3 secondes
              setLoading(false);
            }, 2000); // 3000 millisecondes = 3 secondes
        
            // Nettoyez le timer lorsque le composant est démonté
            const interval = setInterval(() => {
              setCurrentImage((prevImage) => (prevImage + 1) % loadingImages.length);
            }, 100);

            const handleResize = () => {
              setWindowWidth(window.innerWidth);
          };
      
          window.addEventListener('resize', handleResize);

            return () => {
              clearTimeout(timer);
              clearInterval(interval);
              window.removeEventListener('resize', handleResize);
          };
          }, []);

          const [loading, setLoading] = useState(true);
          const [currentImage, setCurrentImage] = useState(0);
  const navigate = useNavigate();

  if(!loading) {
    return (
        <div style={{backgroundColor: '#fcfcfc', height: '100vh',width: '100%',}}>
            <Stack direction={'row'}
                    alignItems='center'
                    spacing={{}}
                     sx={{marginLeft: windowWidth>700?5:2, marginRight:windowWidth>700?5:2, paddingTop:2}}>
                <Button variant="contained" style={{minWidth: 100, marginRight: 'auto'}} startIcon={<ArrowBackIcon />}   onClick={() => {navigate(`/configurationStock/${compte_id}/produits/${compte_id}`)}} >
                        RETOUR                      
                </Button>
                <Tooltip title="Imprimer">
                    <IconButton onClick={() => handlePrint()} >
                        <PrintIcon style={{color: 'blue'}}/>                    
                    </IconButton>
                </Tooltip> 
                <Tooltip title="Exporter en PDF">
                    <IconButton onClick={() => handlePrint()} >
                        <PictureAsPdfIcon  style={{color: 'red'}}/>                     
                    </IconButton>
                </Tooltip> 
                <Tooltip title="Exporter en EXCEL">
                    <IconButton onClick={() => handlePrint()} >
                        <TableChartIcon style={{color: 'green'}}/>                     
                    </IconButton>
                </Tooltip> 
                <Tooltip title="Exporter en CSV">
                    <IconButton onClick={() => handlePrint()} >
                        <DescriptionIcon style={{color: '#2D9596'}} />                     
                    </IconButton>
                </Tooltip>
                <Tooltip title="Copier">
                    <IconButton onClick={() => handlePrint()} >
                        <FileCopyIcon style={{color: 'black'}}/>                     
                    </IconButton>
                </Tooltip>                 
            </Stack>
            <Box class='row'  style={{marginTop:15,
             marginRight:windowWidth>700?5:2,
               marginLeft:windowWidth>700?20:2}}>
                  <div class={windowWidth>1300?"col-sm-3":"col-sm-6"} style={{}}>                              
                    <div class="rounded-container" style={{width: windowWidth>1300? '300px' : windowWidth<400? windowWidth-50:'400px' , 
                    marginBottom:windowWidth>1300?0:15 , 
                    padding:20,                       
                       marginLeft: '5px'}}>
                                        <div class="flex-row" style={{alignItems: 'start', marginBottom:3}}>
                                        <div style={{ width: '200px', height: '70px' }}>
                                        <div>
                                            <p style={{ fontSize: 15,fontWeight: 'bold',
                                                fontFamily:'monospace',
                                                marginBottom: -3,
                                                color:'GrayText'}}>Total Installés</p>
                                            <p style={{ fontSize: 40,fontWeight: 'bold',marginBottom: -3,
                                              color:listCommentaire.length>0?'green':'black'
                                            }}>{listCommentaire.length}</p>
                                          </div>
                                      </div>
                                      <div style={{ width:windowWidth>1300?'75px':'60px', height: windowWidth>1300?'75px':'60px', backgroundColor:'white' }}>                                                  
                                      <img src={Installe} height={'100%'} style={{
                                              //marginBottom:15
                                            }} />
                                          </div>
                                          
                                        </div>
                                        <div>
                                        <p style={{fontSize: 13,
                                       color:'GrayText',
                                       fontWeight:'bold',
                                        fontFamily:'monospace'}}>Total de ce produit chez les clients</p> 
                                        </div>
                                                                
              </div>
              
            </div>
            <div class={windowWidth>1300?"col-sm-3":"col-sm-6"} style={{}}>                              
            <div class="rounded-container" style={{width: windowWidth>1300? '300px' : windowWidth<400? windowWidth-50:'400px' , 
                    marginBottom:windowWidth>1300?0:15 , 
                    padding:20,                       
                       marginLeft: '5px'}}>
                                        <div class="flex-row" style={{alignItems: 'start', marginBottom:3}}>
                                        <div style={{ width: '200px', height: '70px' }}>
                                        <div>
                                            <p style={{ fontSize: 15,fontWeight: 'bold',
                                                fontFamily:'monospace',
                                                marginBottom: -3,
                                                color:'GrayText'}}>Total En Service</p>
                                            <p style={{ fontSize: 40,fontWeight: 'bold',
                                            color:totalEnService>0?'green':'black',
                                              marginBottom: -3,}}>{totalEnService}</p>
                                          </div>
                                      </div>
                                      <div style={{ width:windowWidth>1300?'75px':'60px', height: windowWidth>1300?'75px':'60px', backgroundColor:'white' }}>                                                  
                                      <img src={EnService} height={'100%'} style={{
                                              //marginBottom:15
                                            }} />
                                          </div>
                                          
                                        </div>
                                        <div>
                                        <p style={{fontSize: 13,
                                       color:'GrayText',
                                       fontWeight:'bold',
                                        fontFamily:'monospace'}}>Total de ce produit fonctionnant</p> 
                                        </div>
                                                                
              </div>
              </div>
            <div class={windowWidth>1300?"col-sm-3":"col-sm-6"} style={{}}>                              
            <div class="rounded-container" style={{width: windowWidth>1300? '300px' : windowWidth<400? windowWidth-50:'400px' , 
                    marginBottom:windowWidth>1300?0:15 , 
                    padding:20,                       
                       marginLeft: '5px'}}>
                                        <div class="flex-row" style={{alignItems: 'start', marginBottom:3}}>
                                        <div style={{ width: '200px', height: '70px' }}>
                                        <div>
                                            <p style={{ fontSize: 15,fontWeight: 'bold',
                                                fontFamily:'monospace',
                                                marginBottom: -3,
                                                color:'GrayText'}}>Total sous garantie</p>
                                            <p style={{ fontSize: 40,fontWeight: 'bold',
                                            color:totalSG>0?'green':'black',
                                              marginBottom: -3,}}>{totalSG}</p>
                                          </div>
                                      </div>
                                                                                   
                                      <div style={{ width:windowWidth>1300?'75px':'60px', height: windowWidth>1300?'75px':'60px', backgroundColor:'white' }}>                                                  
                                      <img src={SousGarantie} height={'100%'} style={{
                                              //marginBottom:15
                                            }} />
                                          </div>
                                        </div>
                                        <div>
                                        <p style={{fontSize: 13,
                                       color:'GrayText',
                                       fontWeight:'bold',
                                        fontFamily:'monospace'}}>Sous garantie</p> 
                                        </div>
                                                                
              </div>
              </div>
            <div class="col-sm-3" style={{}}>                              
            {/*<div class="rounded-container" style={{width: windowWidth>1300? '300px' : windowWidth<400? windowWidth-50:'400px' , 
                    marginBottom:windowWidth>1300?0:15 , 
                    padding:20,                       
                       marginLeft: '5px'}}>
                                        <div class="flex-row" style={{alignItems: 'start', marginBottom:3}}>
                                        <div style={{ width: '200px', height: '70px' }}>
                                        <div>
                                            <p style={{ fontSize: 15,fontWeight: 'bold',
                                                fontFamily:'monospace',
                                                marginBottom: -3,
                                                color:'GrayText'}}>Votre Code Promo</p>
                                            <p style={{ fontSize: 35,fontWeight: 'bold',marginBottom: -3,}}>{code_parrainage!=''?code_parrainage:''}</p>
                                          </div>
                                      </div>
                                      <div style={{ width:windowWidth>1300?'75px':'60px', height: '35px', color:'white' }}>                                                  
                                      <Lottie 
                                        animationData={userCompte}
                                        loop={true}                                        
                                        />
                                          </div>
                                          
                                        </div>
                                        <div>
                                        <p style={{fontSize: 13,
                                       color:'GrayText',
                                       fontWeight:'bold',
                                        fontFamily:'monospace'}}>{'Partagez'}</p> 
                                        </div>
                                                                
              </div>*/}  
            </div>  
            </Box>
            <Stack direction={'column'}
                    alignItems='center'
                    sx={{marginTop: 3,
                      marginLeft: windowWidth>700?5:2, 
                      marginRight: windowWidth>700?5:2,
                      borderRadius: 5, 
                      boxShadow: '0px 0px 5px #43766C',
                      backgroundColor:'white'}}>
                  <Stack sx={{fontWeight: 600, fontSize: 20, margin: 2}}>
                    État des installations
                  </Stack>
                  {/*
                  <Box style={{margin:10, width:windowWidth>700?600:350 ,padding:10, borderRadius: 10, boxShadow: '0px 0px 5px #43766C', }}>
                    <h5>Graphique Mensuel des Équipements</h5>
                    {chartData ? (
                      <Line options={options} data={chartData} />
                    ) : (
                      <p>Chargement des données...</p>
                    )}
                  </Box>
                  */}
                  <Stack sx={{marginBottom: 10}}>
                    {windowWidth>700?
                    <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead style={{backgroundColor: '#43766C',}}>
                                    <TableRow>
                                        <TableCell align="center" style={{color: 'white', fontWeight: 600, fontSize: 20}} colSpan={6}>Produit {produit.nomProd}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Numéro</TableCell>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Agence</TableCell>                        
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >No-Serie</TableCell> 
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Garantie</TableCell>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Date installation</TableCell>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Statut</TableCell>                                    
                                    </TableRow>
                                    {listCommentaire.length === 0 ? (
                                        <TableRow>
                                          <TableCell colSpan={6} align="center" style={{fontWeight: 600, color: 'red'}}>
                                            Cet équipement n'a pas encore recu de maintenances antérieures.
                                            <br />
                                            Pensez à bien vérifier le numéro de série choisi. Merci
                                          </TableCell>
                                        </TableRow>
                                      ) : (
                                        data.map((row, index) => (
                                          <TableRow key={index}>
                                            <TableCell align="center" style={{fontWeight: 600}}>{index + 1}</TableCell>
                                            <TableCell align="center" style={{fontWeight: 600}}>{row.nomAgence}</TableCell>
                                            <TableCell align="center" style={{fontWeight: 600}}>{row.numeroSerie}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600,
                                                color: dateActuelle>=row.dateDG && dateActuelle<=row.dateFG ?'green':'red' }}>
                                                 <Button style={{color:'white',
                                                  backgroundColor: dateActuelle>=row.dateDG && dateActuelle<=row.dateFG ? '#8FD14F':'#DD4A48',
                                                  borderRadius:15,
                                                  fontFamily:'monospace', padding:2, textTransform:'none'}}>
                                                   {dateActuelle>=row.dateDG && dateActuelle<=row.dateFG ?'OUI':'NON'}
                                                   </Button>
                                            </TableCell>
                                            <TableCell align="center" style={{fontWeight: 600, color:'blue'}}>{format(new Date(row.created_at), 'dd-MM-yyyy')}</TableCell>
                                            <TableCell align="center" style={{fontWeight: 600,color: row.statutEquipement!='En service'?'red':'green' }}>{row.statutEquipement}</TableCell>
                                          </TableRow>
                                        ))
                                      )}
                                                   
                                                </TableBody>
                            </Table>
                    </TableContainer>
                    :
                    <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 150, maxWidth: 500 }} aria-label="simple table">
                                <TableHead style={{backgroundColor: '#43766C',}}>
                                    <TableRow>
                                        <TableCell align="center" style={{color: 'white', fontWeight: 600, fontSize: 20}} colSpan={5}>Équipement {produit.nomProd}</TableCell>
                                    </TableRow>
                                </TableHead>
                                {data.map((row, index) => (                      
                                <TableBody>    
                                                               
                                    <TableRow>                                            
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#D3F1DF'}} colSpan={2} >Agence</TableCell>                                           
                                    </TableRow>
                                    <TableRow>                                            
                                            <TableCell align="center" style={{fontWeight: 600}} colSpan={2}>{row.nomAgence}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                    <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} colSpan={2} >No-Serie</TableCell>
                                    </TableRow>
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600}} colSpan={2}>{row.numeroSerie}</TableCell>
                                    </TableRow>
                                    <TableRow> 
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Garantie</TableCell>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Date installation</TableCell>
                                    </TableRow>
                                    <TableRow>                                            
                                            <TableCell align="center" style={{fontWeight: 600,
                                                color: dateActuelle>=row.dateDG && dateActuelle<=row.dateFG ?'green':'red' }}>
                                                 <Button style={{color:'white',
                                                  backgroundColor: dateActuelle>=row.dateDG && dateActuelle<=row.dateFG ? '#8FD14F':'#DD4A48',
                                                  borderRadius:15,
                                                  fontFamily:'monospace', padding:2, textTransform:'none'}}>
                                                   {dateActuelle>=row.dateDG && dateActuelle<=row.dateFG ?'OUI':'NON'}
                                                   </Button>
                                            </TableCell>
                                            <TableCell align="center" style={{fontWeight: 600, color:'blue'}}>{format(new Date(row.created_at), 'dd-MM-yyyy')}</TableCell>
                                    </TableRow>
                                    <TableRow>        
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} colSpan={2} >Statut</TableCell>                                    
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="center" colSpan={2} style={{fontWeight: 600,color: row.statutEquipement!='En service'?'red':'green' }}>{row.statutEquipement}</TableCell>                                          
                                    </TableRow>                                                                                                    
                                     
                                  </TableBody>
                                   ))}
                            </Table>
                    </TableContainer>
  }
                  </Stack>  
                  <div class={windowWidth>700?"flex-row":"flex-column"} style={{margin: 10}}>
                        <nav > 
                            <ul className='pagination'>
                                <li className='page-item'>
                                  <a href='#' className='page-link'
                                    onClick={prePage}>Préc</a>
                                </li>
                                {
                                  getPageNumbersToShow().map((n,i) =>
                                  (
                                    <li className= {`page-item  ${currentPage=== n ? 'active' : '' }`} key={i}>
                                      <a href='#' className='page-link'
                                      onClick={() => changeCPage(n)}>{n}</a>
                                    </li>
                                  )
                                  )
                                }
                                <li className='page-item'>
                                  <a href='#' className='page-link'
                                    onClick={nextPage}>Suiv</a>
                                </li>
                            </ul>
                        </nav>
                        <div>
                          <h5>Total : {listCommentaire.length}</h5> 
                        </div>
                </div>                 
            </Stack>
           
        </div>
        
    );

    function nextPage(){
      if( firstIndex+10 <listCommentaire.length)
      {
        setCurrentPage(currentPage + 1);
      }
    }

    function prePage(){
      if(firstIndex-1>0)
      {
        setCurrentPage(currentPage - 1);
      }
    }

    function changeCPage(id){
      setCurrentPage(id);
    }

  }
  else
  {
      return(
          <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh', // 100% de la hauteur de la vue
          }}
        >
          <Stack direction={'column'} alignItems={'center'} spacing={1}>
              <CircularProgress />
              <p style={{color: '#387ADF', fontWeight: 600}}>Chargement</p>
          </Stack>
        </Box>
      );
  }
}