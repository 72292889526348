import { Box, Button, Stack } from "@mui/material";
import React, { useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";

const SignaturePad = ({ onSave }) => {
  const sigPad = useRef({});
  const [isEmpty, setIsEmpty] = useState(true);

  const clear = () => {
    sigPad.current.clear();
    setIsEmpty(true);
  };

  const save = () => {
    if (!sigPad.current.isEmpty()) {
      const signatureData = sigPad.current.getTrimmedCanvas().toDataURL("image/png");
      onSave(signatureData); // Envoie la signature vers le parent
      console.log(signatureData);
    } else {
      alert("Veuillez signer avant d'enregistrer.");
    }
  };

  return (
    <Box >
      <h5>Signature</h5>
      <Box  sx={{
         boxShadow: '0px 0px 5px rgb(22, 182, 223)',
         borderRadius:2,
         marginBottom:2
      }}>
      <SignatureCanvas
        ref={sigPad}
        penColor="black"
        canvasProps={{ width: 300, height: 200, className: "sigCanvas" }}
        onEnd={() => setIsEmpty(false)}
      />   
      </Box>  
      <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
            <Button variant="contained" onClick={save}>
              Enregistrer
            </Button>
            <Button variant="outlined" onClick={clear}>
              Effacer
            </Button>
      </Box>
    </Box>
  );
};

export default SignaturePad;
