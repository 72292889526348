import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Nav from '../../composants/nav.js';
import axios from 'axios';
import {CircularProgress, Box,Dialog,DialogActions, Typography, DialogContent, DialogContentText, DialogTitle, TextField,Stack, Container , IconButton, Button, Paper, TableRow, TableHead, TableContainer, TableCell, TableBody, Table, Tooltip, Autocomplete, MenuItem} from '@mui/material';
import { Navigate, useNavigate, useParams} from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add.js';
import PrintIcon from '@mui/icons-material/Print';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TableChartIcon from '@mui/icons-material/TableChart';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DescriptionIcon from '@mui/icons-material/Description';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import PaidIcon from '@mui/icons-material/Paid';
import useMediaQuery from '@mui/material/useMediaQuery';
import SyncIcon from '@mui/icons-material/Sync';
import { useTheme } from '@mui/material/styles';
import { format } from 'date-fns';
import * as XLSX from 'xlsx'
import Fuse from 'fuse.js';
import B from '../images/B.png';
import B2 from '../images/B2.png';
import H from '../images/H.png';
import hand from '../images/hand.png';
import laptop from '../images/laptop.png';
import lock from '../images/lock.png';
import T from '../images/T.png';
import techni from '../images/techni.png';
import wallet from '../images/wallet.png';
import m2agroup from '../images/m2a.png';

const loadingImages = [
     B,
     T,
     techni,
     laptop,
     lock,
     hand,
     B2,     
     wallet
    // Ajoutez autant d'images que nécessaire
  ];

  var BACKEND_URL = 'https://polygonBac.bt-support.net/api/';
  var countData=1;

export default function Facture() {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [factureData, setfactureData] = useState([]);
    const [listTypeFacture, setlistTypeFacture] = useState([]);
    const [backendErrorMessage, setBackendErrorMessage] = useState('');
    const [typeFacture, setTypeFacture] = useState('');
    const [objetFacture, setObjetFacture] = useState('');
    const [dateFacture, setDateFacture] = useState('');
    const [totalHTT, setTotalHTT] = useState('');
    const [totalPaye, setTotalPaye] = useState('');
    const [totalImpaye, setTotalImpaye] = useState('');
    const [nSticker, setNSticker] = useState('');
    const [tck, setTck] = useState('');
    const [nBonCommande, setNBonCommande] = useState('');
    const [taxe, setTaxe] = useState('');
    const [valeurTaxe, setValeurTaxe] = useState('');
    const [image, setImage] = useState(null);
    let imageBase64 = null;

    const { compte_id } = useParams();

    let [currentPage, setCurrentPage] =useState(1);    
    const dataPerPage = 10;
    const lastIndex = currentPage * dataPerPage;
    const firstIndex = lastIndex - dataPerPage;
    const data = factureData.slice(firstIndex, lastIndex);
    const nPage = Math.ceil(factureData.length /dataPerPage);
    const numbers = [...Array(nPage+1).keys()].slice(1);
    const maxPagesToShow = windowWidth>700?5:3;

    const getPageNumbersToShow = () => {
      const startPage = Math.max(1, currentPage - maxPagesToShow);
      const endPage = Math.min(nPage, currentPage + maxPagesToShow);
    
      return [...Array(endPage - startPage + 1).keys()].map((i) => startPage + i);
    };

    const [open, setOpen] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };
      const action = (
        <React.Fragment>
          <Button color="secondary" size="small" onClick={handleClose}>
            UNDO
          </Button>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      );

    const updateDimensions = () => {
      const width = window.innerWidth
      setWindowWidth(width)
    };

    const handlePrint = () => {
        const printWindow = window.open('', '_blank');
            
              const tableRows = factureData.map((row, index) => (
                `<tr>
                      <td align="center">${index+1}</td>
                      <td align="center">${row.refFacture}</td>
                      <td align="center">${row.name}</td>
                      <td align="center">${row.objet}</td>
                      <td align="center">${row.tckFacture}</td>                      
                      <td align="center">${format(new Date(row.dateFacture), 'dd-MM-yyyy')}</td>
                      <td align="center">${row.statutFacture}</td>
                      <td align="center">${row.totalHTT}</td>
                      <td align="center">${row.nomTaxe == null || row.valeurTaxe == null ? 0 : `${row.nomTaxe} Valeur : ${row.valeurTaxe}`}</td>
                      <td align="center">${row.totalTTC}</td>                                         
                </tr>`
              )).join('');
              
              // Utilisez la chaîne HTML générée pour construire le document HTML
              const htmlContent = `
                <html lang="fr">
                  <head>
                    <meta charset="UTF-8">
                    <meta name="viewport" content="width=device-width, initial-scale=1.0">
                    <title>Tableau des factures</title>                
                   <style>
                    body { font-family: Arial, sans-serif; }
                    table { width: 100%; border-collapse: collapse; margin-bottom: 20px; }
                    th, td { border: 1px solid #ddd; padding: 8px; text-align: left; }
                    th { background-color: #f2f2f2; }
                    .header {
                      border-bottom: 2px solid #000;
                      margin-bottom: 20px;
                      padding-bottom: 10px;
                  }
                  .header img {
                      width: 200px;
                      height: auto;
                      float: left;
                      margin-right: 20px;
                  }
                  .header h1,
                  .header p {
                      margin: 0;
                      padding: 0;
                  }
                  /* Aligne les éléments pour l'impression */
                  .header h1 {
                      font-size: 24px;
                      line-height: 1.2;
                      color: red
                  }
                  .header p {
                      font-size: 14px;
                      line-height: 1.4;
                  }
                  </style>
                  </head>
                  <body>
                  <div class="printable header">
                    <img src=${m2agroup} alt="Logo">                    
                    <h1>POLYGON BIO SERVICES SARL</h1>
                    <p>09 BP 969 Ouagadougou 09</p>
                    <p>Bureau     : (+226) 25 46 29 21</p>
                    <p>Portable 1 : (+226) 70 80 82 08</p>
                    <p>Portable 2 : (+226) 79 67 11 11</p>
                    <p>Whatsapp/ WeChat : (+226) 76 59 71 28</p>
                    <p>E-mail : polygonbioservice@yahoo.fr</p>
                    <p>www.polygonbioservices.com</p>
                    <p>Situé au quartier Sinyiri côté Ouest de la ZAD (SIAO)</p>
                    <p>Siège social 01 BP 6727 OUAGA 01</p>                    
                </div>
                    <h2>Tableau des factures</h2>
                    <table>
                      <thead>
                        <tr>
                          <td align="center" style="font-weight: 600; background-color: #F6F5F5;">Numéro</td>
                          <td align="center" style="font-weight: 600; background-color: #F6F5F5;">Référence</td>
                          <td align="center" style="font-weight: 600; background-color: #F6F5F5;">Enregistreur</td>
                          <td align="center" style="font-weight: 600; background-color: #F6F5F5;">Objet</td>
                          <td align="center" style="font-weight: 600; background-color: #F6F5F5;">Ticket</td>
                          <td align="center" style="font-weight: 600; background-color: #F6F5F5;">Date facture</td>
                          <td align="center" style="font-weight: 600; background-color: #F6F5F5;">Statut</td>
                          <td align="center" style="font-weight: 600; background-color: #F6F5F5;">Total HTT</td>
                          <td align="center" style="font-weight: 600; background-color: #F6F5F5;">Taxe</td>
                          <td align="center" style="font-weight: 600; background-color: #F6F5F5;">Total TTC</td>  
                         </tr>
                      </thead>
                      <tbody>
                        ${tableRows}
                      </tbody>
                    </table>                    
                  </body>
                </html>`;
              
              // Utilisez document.write pour écrire le contenu HTML dans la fenêtre d'impression
              printWindow.document.write(htmlContent);
              printWindow.document.close();
              printWindow.print();
      };



// Fonction pour gérer le changement de fichier
const handleFileChange = (e) => {
  setImage(e.target.files[0]);
};

    const navigate = useNavigate();
  const handleLinkClick = (link) => {
    // Ajoutez la logique pour afficher la page correspondante en fonction du lien cliqué
    // Vous pouvez utiliser une bibliothèque de routage ou gérer manuellement le contenu ici
    navigate(`/${link}`)
};

    const request = async () => {
      try{
        await axios.get(`${BACKEND_URL}facture/proformat/${compte_id}`)
        .then(({data}) => {
            console.log(data);
            setfactureData(data);
        }).catch ((error) =>
        {
            setBackendErrorMessage(error.response.data.error);
        });
        

        await axios.get(`${BACKEND_URL}config_facture/proformat/${compte_id}`)
        .then(({data}) => {            
            setlistTypeFacture(data);
            console.log(listTypeFacture);
        }).catch ((error) =>
        {
            setBackendErrorMessage(error.response.data.error);
        });

        await axios.get(`${BACKEND_URL}facture/totalPayeImPaye/${compte_id}`)
        .then(({data}) => {            
            setTotalImpaye(data['total_impaye']);
            setTotalPaye(data['total_paye']);
            //console.log(data['total_paye']);
        }).catch ((error) =>
        {
            setBackendErrorMessage(error.response.data.error);
        });
      } catch (error) {
        console.log(error)
      }
    }

    const FacturePaye = async (e) => {
      await  axios.get(`${BACKEND_URL}facture/proformatPaye/${compte_id}`)
      .then(({data}) => {
          setfactureData(data);
          console.log(data);
      }).catch ((error) =>
      {
          setBackendErrorMessage(error.response.data.error);
      });
    }

    const FactureImpaye = async (e) => {
      await axios.get(`${BACKEND_URL}facture/proformatImpaye/${compte_id}`)
     .then(({data}) => {
         setfactureData(data);
         console.log(data);
     }).catch ((error) =>
     {
         setBackendErrorMessage(error.response.data.error);
     });
   }


    const handleClickOpenDialog = () => {
        setOpenDialog(true);
      };
    
      const handleCloseDialog = () => {
        setBackendErrorMessage('')
        setOpenDialog(false);
      };


      const updateStatutFacture = async (idFacture, newStatut) => {
        
        try {
            // L'URL de l'API
            const response = await axios.put(`${BACKEND_URL}facture/updateStatutFacture/${idFacture}/${compte_id}`, {
                //statutFacture: newStatut  // Nouveau statut de la facture
            });
    
            // Si la requête est réussie
            console.log('Facture mise à jour avec succès:', response.data);
            return response.data;
        } catch (error) {
            // Gestion des erreurs
            console.error('Erreur lors de la mise à jour du statut de la facture:', error.response ? error.response.data : error.message);
            return error.response ? error.response.data : { error: 'Une erreur est survenue' };
        }
    };
      
    const updateReglerFacture = async (idFacture) => {
        
      try {
          // L'URL de l'API
          const response = await axios.get(`${BACKEND_URL}facture/reglement2/${idFacture}/${compte_id}`, {
              //statutFacture: newStatut  // Nouveau statut de la facture
          });
  
          // Si la requête est réussie
          console.log('Reglement succès:', response.data);
          return response.data;
      } catch (error) {
          // Gestion des erreurs
          console.error('Erreur lors de la mise à jour du statut de la facture:', error.response ? error.response.data : error.message);
          return error.response ? error.response.data : { error: 'Une erreur est survenue' };
      }
  };
    
      const ajoutFacture = async (e) => {
        if(objetFacture=='')
        {
          setBackendErrorMessage('Veuillez entrer l\'objet de la facture')
        }
        else if (typeFacture=='')
        {
          setBackendErrorMessage('Veuillez entre le type de facture')
        }
        else if (dateFacture=='')
        {
          setBackendErrorMessage('Veuillez entre la date de facture')
        }
        else if (totalHTT=='')
        {
          setBackendErrorMessage('Veuillez entre le montant total HTT')
        }
        else {
          if(taxe=='TVA')
            {
              setValeurTaxe(18);
              console.log(valeurTaxe)
            }

        
        if (image) {
            try {
                const reader = new FileReader();
                reader.readAsDataURL(image);
                reader.onloadend = () => {
                  if (reader.readyState === 2) {
                    imageBase64 = reader.result; // L'image en base64
                    console.log('Lecture terminée, image en base64 :', imageBase64);
                    // Vous pouvez maintenant envoyer cette image en base64 à votre backend
                           axios.post(`${BACKEND_URL}facture/create/${compte_id}`, {
                                typeFacture: typeFacture,
                                objet: objetFacture,
                                dateFacture: dateFacture,
                                totalHTT: totalHTT,
                                nSticker: nSticker,
                                nBonCommande: nBonCommande,
                                nomTaxe : taxe,   
                                tckFacture: tck,
                                statutFacture : 'En attente de validation',
                                image: imageBase64, // Envoi de l'image en base64                    
                          }).then((data) =>{
                            request();
                            console.log(data)
                            setOpenDialog(false);
                          })
                          .catch(error => {
                            console.error('Erreur de requête :', error);
                            setBackendErrorMessage(error.response.data.error)
                            });
                }
                    //imageBase64 = reader.result; // L'image en base64
                };
                console.log(reader)
                console.log(image)
            } catch (error) {
                console.error("Erreur de conversion de l'image en base64", error);
                setBackendErrorMessage("Erreur de conversion de l'image");
                return;
            }
        }
        console.log(imageBase64);
                
                  }   
      };


      const Deleterequest = async (facture_id) => {
        if (!window.confirm('Voulez-vous vraiment supprimer?')) {
          return;
        }    
        try {
          await axios.delete(`${BACKEND_URL}facture/destroy/${facture_id}/${compte_id}`);
          console.log(`Approvisionnement avec l'ID ${facture_id} supprimé avec succès`);
          // Ajoutez ici la logique supplémentaire après la suppression
          setOpen(true);
          request()
          window.alert('Element supprimer avec succès')
        } catch (error) {
          console.error('Erreur lors de la suppression de l\'approvisionnement', error);
          window.alert(error.response.data.error)
          // Ajoutez ici la gestion des erreurs
        }
    }

    const [searchQuery, setSearchQuery] = useState('');
    // Configuration de Fuse.js
const fuseOptions = {
  keys: [ 'refFacture', 'tckFacture',], // Les champs à rechercher
 includeScore: true,
};

// Initialiser un nouvel objet Fuse avec les options
const fuse = new Fuse(factureData, fuseOptions);

// Fonction pour gérer le changement de la recherche
const handleSearchChange = (e) => {
 const query = e.target.value;
 setSearchQuery(query);

 // Effectuer la recherche et mettre à jour les résultats
 const results = fuse.search(query);

 if (query==''){
   //setApproData(results.map((result) => result.item));
   request()
 }
 else{
   setfactureData(results.map((result) => result.item));
 }

};

const handleExportExcel = () => {
  var wb = XLSX.utils.book_new();
  var ws = XLSX.utils.json_to_sheet(factureData);

  //XLSX.utils.sheet_add_aoa(ws, [['Relevée des sorties']], { origin: -1 });

  // Mettre la première ligne en gras
  //XLSX.utils.sheet_set_range_style(ws, { s: { font: { bold: true } }, e: { font: { bold: true } } });

  XLSX.utils.book_append_sheet(wb, ws , 'Proformats');

  XLSX.writeFile(wb , 'Relevé des proformats.xlsx');

}

    useEffect(() => {
      try {
        request();
        
      } catch (error) {
        console.log(error)
      }
        const timer = setTimeout(() => {
          // Mettez à jour l'état pour arrêter le chargement après 3 secondes
          setLoading(false);
        }, 3000); // 3000 millisecondes = 3 secondes
    
        // Nettoyez le timer lorsque le composant est démonté
        const interval = setInterval(() => {
          setCurrentImage((prevImage) => (prevImage + 1) % loadingImages.length);
        }, 300);

        const handleResize = () => {
          setWindowWidth(window.innerWidth);
      };
    
      window.addEventListener('resize', handleResize);

        return () => {
          clearTimeout(timer);
          clearInterval(interval);
          window.removeEventListener('resize', handleResize);
      };

      }, []);
      const [loading, setLoading] = useState(true);
      const [currentImage, setCurrentImage] = useState(0);

      if(!loading) {
    return (
      <div style={{backgroundColor: '#fcfcfc', height: '100vh',width: '100%',}}>
      <Nav compte_id={compte_id} />
      <Stack direction={'row'}
              alignItems='center'
              spacing={{}}
               sx={{margin: 3}}>
          <div style={{marginRight: 'auto'}}><h3>Gestion des factures</h3></div>
          <Tooltip title="Imprimer">
              <IconButton onClick={() => handlePrint()} >
                  <PrintIcon style={{color: 'blue'}}/>                    
              </IconButton>
          </Tooltip> 
          <Tooltip title="Exporter en PDF">
              <IconButton onClick={() => handlePrint()} >
                  <PictureAsPdfIcon  style={{color: 'red'}}/>                     
              </IconButton>
          </Tooltip> 
          <Tooltip title="Exporter en EXCEL">
              <IconButton onClick={() => handleExportExcel()} >
                  <TableChartIcon style={{color: 'green'}}/>                     
              </IconButton>
          </Tooltip> 
          <Tooltip title="Exporter en CSV">
              <IconButton onClick={() => {}} >
                  <DescriptionIcon style={{color: '#2D9596'}} />                     
              </IconButton>
          </Tooltip>
          <Tooltip title="Copier">
              <IconButton onClick={() => {}} >
                  <FileCopyIcon style={{color: 'black'}}/>                     
              </IconButton>
          </Tooltip>                 
      </Stack>                  
            <Stack direction={'column'}
              alignItems='center'
              sx={{marginTop: 3,
                marginLeft: 2, 
                marginRight: 2, 
                borderRadius: 5, 
                minWidth:windowWidth-150,
                boxShadow: '0px 0px 5px #7D0A0A',
                backgroundColor:'white'}}>                  
            <Stack sx={{fontWeight: 600, fontSize: 20, margin: 2}}>
              Liste des factures
            </Stack>
            <Stack>
            <Stack direction={windowWidth>700?'row':'column'}
          spacing={2}
        alignItems='center'
        justifyContent='center'
        marginBottom='10px'
    >
                    <TextField type="text" 
                          value={searchQuery} 
                          onChange={handleSearchChange} 
                          placeholder="Rechercher...">
                    </TextField>
                    <div style={{marginBottom: 10, //marginRight: 3
                        }}>
                <Button variant='contained' onClick={() => {FacturePaye()}}>Facture Payé</Button>
                        </div>
                        
                <div style={{marginBottom: 10, //marginRight: 3
                }}>
                <Button variant='contained' onClick={() => {FactureImpaye()}}>Facture Impayé</Button>
                </div>
                <div style={{marginBottom: 10, alignContent: 'center'}}>
                
                            <Tooltip title="Reinitialiser les filtres" >
                              <IconButton style={{ color: '#0D9276'}} onClick={()=>{   
                                    request()
                              }} >
                                <SyncIcon />
                              </IconButton>
                            </Tooltip>  
                </div>
                </Stack>
                    {windowWidth>700?
              <TableContainer component={Paper}>
                      <Table sx={{ minWidth:windowWidth-200, }} aria-label="simple table">
                          <TableHead style={{backgroundColor: '#7D0A0A',}}>
                              <TableRow>
                                  <TableCell align="center" style={{color: 'white', fontWeight: 600, fontSize: 20}} colSpan={9}>
                                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                    <Typography style={{
                                      color: 'white',
                                      fontWeight: 600,
                                      fontSize: 20,
                                    }}>
                                    Factures
                                    </Typography>
                                    <IconButton 
                                        style={{ margin: 5, 
                                          color: 'white',
                                        backgroundColor:'#19A7CE' }} 
                                        onClick={() => { handleClickOpenDialog() }}
                                      >
                                        <AddIcon />
                                      </IconButton>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>                                    
                                    <Typography style={{
                                      color: 'white',
                                      //fontWeight: 600,
                                      fontSize: 15,
                                    }}>
                                    T. Paye: <span style={{color:'#9EDF9C', fontWeight:'bold'}}>{totalPaye.toLocaleString('fr-FR')}</span>
                                    </Typography>
                                    <Typography style={{
                                      color: 'white',
                                      //fontWeight: 600,
                                      fontSize: 15,
                                    }}>
                                    T. Impaye: <span style={{color:'#FFF574', fontWeight:'bold'}}>{totalImpaye.toLocaleString('fr-FR')}</span>
                                    </Typography>
                                    </div>
                                  </TableCell>
                              </TableRow>
                          </TableHead>
                          <TableBody>
                              <TableRow>
                                      {/*<TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Numéro</TableCell>*/ }                                                                                                                                                    
                                      <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Référence</TableCell>
                                      <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Enregistreur</TableCell> 
                                      <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Objet</TableCell>
                                      <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Ticket</TableCell> 
                                      <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Date facture</TableCell>                                                                                                                                 
                                      <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Statut facture</TableCell>
                                      <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Régler</TableCell> 
                                      <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Total HTT</TableCell>  
                                      <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Actions</TableCell>                                          
                              </TableRow>
                                  {data.map((row, index) => (
                              <TableRow>
                                      {/*<TableCell align="left" style={{fontWeight: 600}}>{countData+index}</TableCell>*/}                                                                                                                                                        
                                      <TableCell align="left" style={{fontWeight: 600}}>{row.refFacture}</TableCell>     
                                      <TableCell align="left" style={{fontWeight: 600}}>{row.name}</TableCell>                                        
                                      <TableCell align="left" style={{fontWeight: 600}}>{row.objet}</TableCell>
                                      <TableCell align="left" style={{fontWeight: 600}}>{row.tckFacture}</TableCell>                                        
                                      <TableCell align="left" style={{fontWeight: 600}}>{format(new Date(row.dateFacture), 'dd-MM-yyyy')}</TableCell>
                                      <TableCell align="left" style={{fontWeight: 600,
                                         color: row.statutFacture=='En attente de validation'?'red':'green' }}>{row.statutFacture}</TableCell>
                                      <TableCell align="left" style={{fontWeight: 600,
                                         color: row.reglement=='non'?'red':'green' }}>
                                          <Button style={{color:'white',
                                           backgroundColor:row.reglement == 'non'? '#DD4A48':'#8FD14F',
                                           borderRadius:15,
                                           fontFamily:'monospace', padding:2, textTransform:'none'}}>
                                            {row.reglement}
                                            </Button>
                                         </TableCell>
                                      <TableCell align="left" style={{fontWeight: 600, color:'red'}}>{row.totalHTT.toLocaleString('fr-FR')} </TableCell>
                                      <TableCell align="left" style={{fontWeight: 600}}>   
                                            <Stack direction={'column'}
                                                spacing={{sm: 1}}
                                                alignItems={'center'}                                                   
                                            >
                                              <Stack direction={'row'}
                                                spacing={{sm: 0}} >
                                                {/*<Tooltip title='Modifier'>
                                                  <IconButton style={{ color: '#40A2E3' }} onClick={() => { }} >
                                                    <EditIcon />
                                                  </IconButton>
                                                </Tooltip>*/}
                                                <Tooltip title="Voir la facture">
                                                  <IconButton
                                                    style={{ color: '#0D9276' }}
                                                    onClick={() => {
                                                      // Remplacer `row.image_url` par la variable contenant l'URL de l'image
                                                      //const imageUrl = row.image_url; // Supposons que vous avez `image_url` dans `row`

                                                      // Ouvre l'image dans un nouvel onglet
                                                      window.open(`https://polygonbac.bt-support.net${row.image_path}`, '_blank');
                                                    }}
                                                  >
                                                    <DescriptionIcon />
                                                  </IconButton>
                                                </Tooltip>
                                                { row.reglement=='non'?
                                                <Tooltip title='Regler la facture'>
                                                        <IconButton style={{ color: '#FFB200' }} onClick={() => {
                                                          updateReglerFacture(row.id);
                                                        }} >
                                                          <PaidIcon />
                                                        </IconButton>
                                                </Tooltip>  
                                                :
                                                null
                                              }   
                                                <Tooltip title= {row.statutFacture== 'En attente de validation' ?  'Valider la facture':''}>
                                                        <IconButton style={{ color: row.statutFacture== 'En attente de validation' ?  '#40A2E3' : 'black'}} onClick={()=>{
                                                            updateStatutFacture(row.id);                                                           
                                                            }} >
                                                              {row.statutFacture== 'En attente de validation' ? <CheckCircleIcon /> : <CancelIcon />}
                                                          
                                                        </IconButton>
                                                    </Tooltip> 
                                                <Tooltip title='Supprimer'>
                                                        <IconButton style={{ color: '#D24545' }} onClick={() => {Deleterequest(row.id)}} >
                                                          <DeleteIcon />
                                                        </IconButton>
                                                </Tooltip>                                                                                                
                                                </Stack>
                                              <Stack direction={'row'}
                                                spacing={{sm: 0}}>                                                                                                       
                                                </Stack>                                                    
                                            </Stack>                                             
                                                                                       
                                      </TableCell>
                                              </TableRow>
                                                ))}
                                          </TableBody>
                      </Table>
              </TableContainer>
              :
              <TableContainer component={Paper}>
                      <Table sx={{ minWidth:windowWidth-200, }} aria-label="simple table">
                          <TableHead style={{backgroundColor: '#7D0A0A',}}>
                              <TableRow>
                                  <TableCell align="center" style={{color: 'white', fontWeight: 600, fontSize: 20}} colSpan={8}>
                                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                    <Typography style={{
                                      color: 'white',
                                      fontWeight: 600,
                                      fontSize: 20,
                                    }}>
                                    Factures
                                    </Typography>
                                    <IconButton 
                                        style={{ margin: 5, 
                                          color: 'white',
                                        backgroundColor:'#19A7CE' }} 
                                        onClick={() => { handleClickOpenDialog() }}
                                      >
                                        <AddIcon />
                                      </IconButton>
                                    </div>
                                    <Stack direction={'column'} style={{
                                      width:'100%',
                                      textAlign:'left'
                                    }}>
                                      <Typography style={{
                                        color: 'white',
                                        //fontWeight: 600,
                                        fontSize: 15,
                                      }}>
                                      T. Payé: <span style={{color:'#9EDF9C', fontWeight:'bold'}}>{totalPaye.toLocaleString('fr-FR')}</span>
                                      </Typography>
                                      <Typography style={{
                                        color: 'white',
                                        //fontWeight: 600,
                                        fontSize: 15,
                                      }}>
                                      T. Impayé: <span style={{color:'#FFF574', fontWeight:'bold'}}>{totalImpaye.toLocaleString('fr-FR')}</span>
                                      </Typography>
                                    </Stack>
                                  </TableCell>
                              </TableRow>
                          </TableHead>
                          {data.map((row, index) => (
                          <TableBody>
                              <TableRow>
                                      <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#CC2B52', color:'white'}} colSpan={2}>Référence</TableCell>
                              </TableRow>
                              <TableRow>
                                  <TableCell align="center" style={{fontWeight: 600}} colSpan={2}>{row.refFacture}</TableCell>                                           
                              </TableRow>
                              <TableRow>
                                      <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} colSpan={2}>Enregistreur</TableCell>
                              </TableRow>
                              <TableRow>
                                  <TableCell align="center" style={{fontWeight: 600}} colSpan={2}>{row.name}</TableCell>                                           
                              </TableRow>
                              <TableRow>
                                      <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} colSpan={2}>Ticket</TableCell>
                              </TableRow>
                              <TableRow>
                                  <TableCell align="center" style={{fontWeight: 600}} colSpan={2}>{row.tckFacture}</TableCell>                                           
                              </TableRow>
                              <TableRow>
                                      <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} colSpan={2}>Statut</TableCell>
                              </TableRow>
                              <TableRow>
                              <TableCell align="center" colSpan={2} style={{fontWeight: 600,
                                         color: row.statutFacture=='En attente de validation'?'red':'green' }}>{row.statutFacture}</TableCell>                                      
                              </TableRow>
                              <TableRow>
                                      <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Objet</TableCell> 
                                      <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Date facture</TableCell>                                                                                                                                 
                              </TableRow>
                              <TableRow>
                              <TableCell align="center" style={{fontWeight: 600}}>{row.objet}</TableCell>                                        
                                      <TableCell align="center" style={{fontWeight: 600}}>{format(new Date(row.dateFacture), 'dd-MM-yyyy')}</TableCell>                                      
                              </TableRow>
                              <TableRow>                                 
                                      <TableCell align="center" colSpan={2} style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Régler</TableCell>
                              </TableRow>
                              <TableRow>
                                      <TableCell align="center" colSpan={2} >
                                        
                                        <Button style={{color:'white',
                                           backgroundColor:row.reglement == 'non'?  '#DD4A48':'#8FD14F',
                                           fontFamily:'monospace', padding:2, textTransform:'none'}}>
                                            {row.reglement}
                                            </Button>
                                        </TableCell>
                              </TableRow>
                              <TableRow>                                 
                                      <TableCell align="center" colSpan={2} style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Total HTT</TableCell>
                              </TableRow>
                              <TableRow>
                                      <TableCell align="center" colSpan={2} style={{fontWeight: 600, color:'red'}}>{row.totalHTT.toLocaleString('fr-FR')} </TableCell>
                              </TableRow>
                              <TableRow>
                                      <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} colSpan={2}>Actions</TableCell>                                          
                              </TableRow>                                  
                              <TableRow>
                                      <TableCell align="center" style={{fontWeight: 600}} colSpan={2}>   
                                            <Stack direction={'column'}
                                                spacing={{sm: 1}}
                                                alignItems={'center'}                                                   
                                            >
                                              <Stack direction={'row'}
                                                spacing={{sm: 0}} >
                                               <Tooltip title="Voir la facture">
                                                  <IconButton
                                                    style={{ color: '#0D9276' }}
                                                    onClick={() => {
                                                      // Remplacer `row.image_url` par la variable contenant l'URL de l'image
                                                      //const imageUrl = row.image_url; // Supposons que vous avez `image_url` dans `row`

                                                      // Ouvre l'image dans un nouvel onglet
                                                      window.open(`https://polygonbac.bt-support.net${row.image_path}`, '_blank');
                                                    }}
                                                  >
                                                    <DescriptionIcon />
                                                  </IconButton>
                                                </Tooltip>
                                                {row.reglement=='non'?
                                                <Tooltip title='Regler la facture'>
                                                        <IconButton style={{ color: '#FFB200' }} onClick={() => {}} >
                                                          <PaidIcon />
                                                        </IconButton>
                                                </Tooltip> 
                                                :null
      }  
                                                <Tooltip title= {row.statutFacture== 'En attente de validation' ?  'Valider la facture':''}>
                                                        <IconButton style={{ color: row.statutFacture== 'En attente de validation' ?  '#40A2E3' : 'black'}} onClick={()=>{
                                                            updateStatutFacture(row.id);                                                           
                                                            }} >
                                                              {row.statutFacture== 'En attente de validation' ? <CheckCircleIcon /> : <CancelIcon />}
                                                          
                                                        </IconButton>
                                                    </Tooltip> 
                                                <Tooltip title='Supprimer'>
                                                        <IconButton style={{ color: '#D24545' }} onClick={() => {Deleterequest(row.id)}} >
                                                          <DeleteIcon />
                                                        </IconButton>
                                                </Tooltip>                                                                                                
                                                 </Stack>
                                              <Stack direction={'row'}
                                                spacing={{sm: 0}}>                                                                                                       
                                                </Stack>                                                    
                                            </Stack>                                             
                                                                                       
                                      </TableCell>
                                              </TableRow>
                                                
                                          </TableBody>
                                          ))}
                      </Table>
              </TableContainer>
                                              }
              <div class="flex-row" style={{margin: 10}}>
                  <nav > 
                      <ul className='pagination'>
                          <li className='page-item'>
                            <a href='#' className='page-link'
                              onClick={prePage}>Préc</a>
                          </li>
                          {
                            getPageNumbersToShow().map((n,i) =>
                            (
                              <li className= {`page-item  ${currentPage=== n ? 'active' : '' }`} key={i}>
                                <a href='#' className='page-link'
                                onClick={() => changeCPage(n)}>{n}</a>
                              </li>
                            )
                            )
                          }
                          <li className='page-item'>
                            <a href='#' className='page-link'
                              onClick={nextPage}>Suiv</a>
                          </li>
                      </ul>
                  </nav>
                  <div>
                    <h5>Total {factureData.length}</h5> 
                  </div>
          </div>
            </Stack>
            <Button variant="contained" style={{margin: 20}} startIcon={<AddIcon />}   onClick={() => handleClickOpenDialog()} >
              Ajouter                      
            </Button>
            </Stack>
            <Dialog
                  fullScreen={fullScreen}
                  open={openDialog}
                  onClose={handleCloseDialog}
                  aria-labelledby="responsive-dialog-title"
                >
                  <DialogTitle id="responsive-dialog-title">
                    {"Information de la facture"}
                  </DialogTitle>
                  <DialogContent>
                    <Stack direction={'column'}
                        spacing={{sm: 2}}
                        sx={{marginTop: 1}}
                        >
                          {backendErrorMessage && <p style={{ color: 'red' }}>{backendErrorMessage}</p>}
                          <Stack
                              direction={'row'}
                              spacing={{sm: 2}}
                              sx={{marginTop: 1}}
                          >
                            
                            <TextField type='text' 
                                label='Objet de facture'
                                value={objetFacture}
                                style={{minWidth:250}}
                                onChange={(e)=> {
                                    setObjetFacture(e.target.value)
                                }}
                            ></TextField>
                            <Autocomplete
                              id="combo-box-demo"
                              options={listTypeFacture}
                              getOptionLabel={(option) => option.typeFacture}
                              size='medium'
                              style={{minWidth:250}}
                              /*style={{width: width<800 ?  250 :(width*30)/100,
                              backgroundColor: '#ffffff'}}*/
                              value={typeFacture.value}
                              onChange={(event, newValue) => {
                                setTypeFacture(newValue ? newValue.typeFacture:'');
                                console.log(newValue ? newValue.typeFacture:''); // Nouvelle valeur sélectionnée
                                
                                //console.log(selectedFournisseur)
                              }}
                              renderInput={(params) => (
                                <TextField {...params} label="Sélectionnez un type de facture"  />
                              )}
                            />
                          </Stack>
                          <Stack
                              direction={'row'}
                              spacing={{sm: 2}}
                              sx={{marginTop: 1}}
                          >
                            <Stack
                              direction={'column'}>
                                    <label style={{fontWeight: 600}}>Date de facture</label>
                                    <TextField type='date'
                                        value={dateFacture}
                                        style={{minWidth:250}}
                                        onChange={(e)=> {
                                            setDateFacture(e.target.value)
                                        }}
                                    ></TextField>
                            </Stack>
                            <Stack
                              direction={'column'}>
                                    <label style={{fontWeight: 600}}>Total HTT</label>
                                    <TextField type='number'
                                        value={totalHTT}
                                        style={{minWidth:250}}
                                        onChange={(e)=> {
                                            setTotalHTT(e.target.value)
                                        }}
                                    ></TextField>
                            </Stack>
                          </Stack>
                          <label style={{fontWeight: 600}}>Référence ticket</label>
                                    <TextField type='text'
                                        value={tck}
                                        style={{minWidth:250}}
                                        onChange={(e)=> {
                                            setTck(e.target.value)
                                        }}
                                    ></TextField>                       
                          <input type="file" onChange={handleFileChange} />
                            <Button variant="contained" endIcon={<CheckCircleIcon />} onClick={() => {ajoutFacture()}} style={{fontWeight: 600}}>
                              Valider 
                            </Button>
                            
                    </Stack>

                  </DialogContent>
                  <DialogActions>
                    <Button variant='contained' color='error' onClick={handleCloseDialog} autoFocus>
                      Annuler
                    </Button>
                  </DialogActions>
                </Dialog>                                      
          </div>
    );
    
    function nextPage(){
      if(firstIndex+10 < factureData.length)
      {
        setCurrentPage(currentPage + 1);
        countData=countData+10;
      }
    }
  
    function prePage(){
      if(firstIndex-1>0)
      {
        setCurrentPage(currentPage - 1);
        countData=countData-10;
        console.log(countData)
      }
    }
  
    function changeCPage(id){
      setCurrentPage(id);
      countData = ((id*10)-9)
    }
  }
  else
  {
      return(
          <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh', // 100% de la hauteur de la vue
          }}
        >
          <Stack direction={'column'} alignItems={'center'} spacing={1}>
              <CircularProgress />
              <p style={{color: '#387ADF', fontWeight: 600}}>Chargement</p>
          </Stack>
        </Box>
      );
     
  }
}