import logo from './logo.svg';
import './App.css';
import User from './pages/user.js';
import Connexion from './pages/connexion.js';
import * as React from "react";
import {createBrowserRouter, RouterProvider } from "react-router-dom";
import Dashboard from './pages/dashboard.js';
import ConfigurationSysteme from './pages/configSysteme/configurationSysteme.js';
import ConfigurationRole from './pages/configSysteme/configurationRole.js';
import ConfigurationIntervention from './pages/configInterventions/configurationInterventions.js';
import ConfigurationFacturation from './pages/configFacturation/configurationFacturation.js';
import ConfigurationStock from './pages/configStock/configurationStock.js';
import Stocks from './pages/stock/stocks.js';
import Approvisionement from './pages/approvisionement/approvisionement.js';
import FormAppro from './pages/approvisionement/formAppro.js';
import Sorties from './pages/sorties/sortie.js'
import FormSortie from './pages/sorties/formSortie.js';
import Atelier from './pages/atelier/atelier.js';
import Ticket from './pages/ticket/ticket.js';
import Client from './pages/client/client.js';
import AjoutProdSortie from './pages/sorties/ajoutProdSortie.js';
import FormProdSortie from './pages/sorties/formProdSortie.js';
import ModifierFormProdAppro from './pages/approvisionement/ModifierformProdAppro.js';
import ModifierFormProdSortie from './pages/sorties/ModifierFormProdSortie.js';
import AfficherAppro from './pages/approvisionement/afficherAppro.js';
import AfficherSortie from './pages/sorties/afficherSortie.js';
import ConfigurationUtilisateur from './pages/configSysteme/configurationUtilisateur.js';
import ConfigurationTypeProd from './pages/configStock/configurationTypeProd.js';
import ConfigurationMarqueProd from './pages/configStock/configurationMarqueProd.js';
import ConfigurationModeleProd from './pages/configStock/configurationModeleProd.js';
import ConfigurationProduit from './pages/configStock/configurationProduits.js';
import ConfigurationEntrepot from './pages/configStock/configurationEntrepots.js';
import ConfigurationFournisseur from './pages/configStock/configurationFournisseur.js';
import ConfigurationCommerciaux from './pages/configStock/configurationCommerciaux.js';
import ConfigurationTypeClient from './pages/configInterventions/configurationTypeClient.js';
import ConfigurationTypeContrat from './pages/configInterventions/configurationTypeContrat.js';
import DroitAcces from './pages/configSysteme/droitAcces.js';
import AgenceOfClient from './pages/client/agenceOfClient.js';
import ContratOfClient from './pages/client/contratOfClient.js';
import EquipementOfAgence from './pages/client/equipementOfAgence.js';
import EquipementOfClient from './pages/client/equipementOfClient.js';
import Intervention from './pages/ticket/intervention.js';
import HistoriueEquipement from './pages/client/historiquesEquipement.js';
import EquipementIntervention from './pages/ticket/equipementInterv.js';
import AfficherEquipementAtelier from './pages/atelier/afficherEquipement.js';
import Caisse from './pages/caisse/caisse.js';
import Proformat from './pages/facture/proformat.js';
import Facture from './pages/facture/facture.js';
import AfficherClient from './pages/client/afficherClient.js';
import RapportIntervention from './pages/ticket/rapportIntervention.js';
import ChoixCompte from './pages/choixCompte.js';
import AfficherAgence from './pages/client/afficherAgence.js';
import AfficherCompte from './pages/configSysteme/AfficherCompte.js';
import ConfigurationCompteTresorerie from './pages/configSysteme/configurationCompteTresorerie.js';
import Reporting from './pages/reporting.js';
import ReportingEquipement from './pages/reportingEquipement.js';
import EquipementInterventionMobile from './pages/ticket/equipementIntervMobile.js';
import RapportInterventionMobile from './pages/ticket/rapportInterventionMobile.js';
import ProformatMobile from './pages/facture/proformatMobile.js';
import ConfigurationNotifications from './pages/configSysteme/configurationNotifications.js';
import ReportingProduit from './pages/configStock/reportingProduit.js';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Connexion />,
  },

  {
    path: "/user/:compte_id",
    element: <User />,
  },

  {
    path: "/choixCompte/:user_id",
    element: <ChoixCompte />,
  },

  {
    path: "/dashboard/:compte_id",
    element: <Dashboard />,
  },


//////////////////////////////////////////////////////////////////////////////
  {
    path: "/configurationSysteme/:compte_id",
    element: <ConfigurationSysteme />,
  },

  {
    path: "/configurationSysteme/:compte_id/roles/:compte_id",
    element: <ConfigurationRole />,
  },
  {
    path: "/configurationSysteme/:compte_id/roles/:compte_id/droit-acces/:role_id/:compte_id",
    element: <DroitAcces />,
  },

  {
    path: "/configurationSysteme/:compte_id/Utilisateurs/:compte_id",
    element: <ConfigurationUtilisateur />,
  },

  {
    path: "/configurationSysteme/:compte_id/compte-trésorerie/:compte_id",
    element: <ConfigurationCompteTresorerie />,
  },

  {
    path: "/configurationSysteme/:compte_id/Utilisateurs/:compte_id/:user_id/afficher-comptes/:compte_id",
    element: <AfficherCompte />,
  },

/////////////////////////////////////////////////////////////////////////////

  {
    path: "/configurationStock/:compte_id",
    element: <ConfigurationStock />,
  },
  {
    path: "/configurationStock/:compte_id/type-produits/:compte_id",
    element: <ConfigurationTypeProd />,
  },
  {
    path: "/configurationStock/:compte_id/marque-produits/:compte_id",
    element: <ConfigurationMarqueProd />,
  },
  {
    path: "/configurationStock/:compte_id/modele-produits/:compte_id",
    element: <ConfigurationModeleProd />,
  },
  {
    path: "/configurationStock/:compte_id/produits/:compte_id",
    element: <ConfigurationProduit />,
  },
  {
    path: "/configurationStock/:compte_id/entrepots/:compte_id",
    element: <ConfigurationEntrepot />,
  },
  {
    path: "/configurationStock/:compte_id/fournisseurs/:compte_id",
    element: <ConfigurationFournisseur />,
  },
  {
    path: "/configurationStock/:compte_id/commerciaux/:compte_id",
    element: <ConfigurationCommerciaux />,
  },
  {
    path: "/configurationStock/:compte_id/produits/:compte_id/:produit_id/equipement/:compte_id",
    element: <ReportingProduit />,
  },
  
/////////////////////////////////////////////////////////////////////////////

  {
    path: "/configurationIntervention/:compte_id",
    element: <ConfigurationIntervention />,
  },
  {
    path: "/configurationIntervention/:compte_id/type-client/:compte_id",
    element: <ConfigurationTypeClient />,
  },
  {
    path: "/configurationIntervention/:compte_id/type-contrat/:compte_id",
    element: <ConfigurationTypeContrat />,
  },

/////////////////////////////////////////////////////////////////////////////
  {
    path: "/configurationFacturation/:compte_id",
    element: <ConfigurationFacturation />,
  },
/////////////////////////////////////////////////////////////////////////////
  {
    path: "/stocks/:compte_id",
    element: <Stocks />,
  },
/////////////////////////////////////////////////////////////////////////////
  {
    path: "/approvisionements/:compte_id",
    element: <Approvisionement />,
  },
  {
    path: "/formulaire-approvisionement/:compte_id",
    element: <FormAppro />,
  },
  {
    path: "/afficher-approvisionement/:appro_id/:compte_id",
    element: <AfficherAppro />,
  },
  {
    path: "/modification-approvisionement/:appro_id/:compte_id",
    element: <ModifierFormProdAppro />,
  },
/////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////
  {
    path: "/sorties/:compte_id",
    element: <Sorties />,
  },
  {
    path: "/formulaire-sorties/:compte_id",
    element: <FormSortie />,
  },
  {
    path: "/ajout-produits-sorties/:compte_id",
    element: <AjoutProdSortie />,
  },
  {
    path: "/formulaire-produits-sorties/:compte_id",
    element: <FormProdSortie />,
  },
  {
    path: "/modification-sortie/:releveSortie_id/:compte_id",
    element: <ModifierFormProdSortie />,
  },
  {
    path: "/afficher-sortie/:sortie_id/:compte_id",
    element: <AfficherSortie />,
  },
/////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////
  {
    path: "/ateliers/:compte_id",
    element: <Atelier />,
  },

  {
    path: "/ateliers/:compte_id/:equipement_id/afficher-equipement-atelier/:compte_id",
    element: <AfficherEquipementAtelier />,
  },
/////////////////////////////////////////////////////////////////////////////
  {
    path: "/interventions/:compte_id",
    element: <Intervention />,
  },
/////////////////////////////////////////////////////////////////////////////

  {
    path: "/tickets/:compte_id",
    element: <Ticket />,
  },

  {
    path: "/interventions/:compte_id",
    element: <Intervention />,
  },
  {
    path: "/interventions/:compte_id/:agence_id/equipements/:interv_id/:compte_id",
    element: <EquipementIntervention />,
  },

  {
    path: "/interventions/:compte_id/:agence_id/equipementsMobile/:interv_id/:compte_id",
    element: <EquipementInterventionMobile />,
  },

  {
    path: "/interventions/:compte_id/:tck_id/rapport/:interv_id/:agence_id/:compte_id",
    element: <RapportIntervention />,
  },

  {
    path: "/interventions/:compte_id/:tck_id/rapportMobile/:interv_id/:agence_id/:compte_id",
    element: <RapportInterventionMobile />,
  },

/////////////////////////////////////////////////////////////////////////////
  {
    path: "/clients/:compte_id",
    element: <Client />,
  },

  //---------------------------------------------------------------//
  {
    path: "/clients/:compte_id/:client_id/agences/:compte_id",
    element: <AgenceOfClient />,
  },

  {
    path: "/clients/:compte_id/:client_id/agences/:compte_id/:agence_id/equipements/:compte_id",
    element: <EquipementOfAgence />,
  },

  {
    path: "/clients/:compte_id/:client_id/agences/:compte_id/:agence_id/afficher-agence/:compte_id",
    element: <AfficherAgence />,
  },

  {
    path: "/clients/:compte_id/:client_id/agences/:compte_id/:agence_id/equipements/:compte_id/:numeroSerie/historiques/:compte_id",
    element: <HistoriueEquipement />,
  },
  //---------------------------------------------------------------//

  {
    path: "/clients/:compte_id/:client_id/contrats/:client_name/:compte_id",
    element: <ContratOfClient />,
  },
  {
    path: "/clients/:compte_id/:client_id/equipements/:compte_id",
    element: <EquipementOfClient />,
  },

  {
    path: "/clients/:compte_id/:client_id/afficher-client/:compte_id",
    element: <AfficherClient />,
  },

/////////////////////////////////////////////////////////////////////////////
  {
    path: "/caisse/:compte_id",
    element: <Caisse />,
  },
/////////////////////////////////////////////////////////////////////////////
  {
    path: "/reporting/:compte_id",
    element: <Reporting />,
  },

  {
    path: "/reporting/:compte_id/:numeroSerie/equipement/:compte_id",
    element: <ReportingEquipement />,
  },
  
/////////////////////////////////////////////////////////////////////////////

{
  path: "/factures/:compte_id",
  element: <Facture />,
},

/////////////////////////////////////////////////////////////////////////////

{
  path: "/proformats/:compte_id",
  element: <Proformat />,
},
{
  path: "/proformatsMobile/:compte_id",
  element: <ProformatMobile />,
},


/////////////////////////////////////////////////////////////////////////////

{
  path: "/configurationSysteme/:compte_id/notifications/:compte_id",
  element: <ConfigurationNotifications />,
},

/////////////////////////////////////////////////////////////////////////////
]);

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
